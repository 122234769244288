import React from "react";
import PropTypes from "prop-types";
import { graphql, Link } from "gatsby";
import { Box, Button, Center, Heading, Text, VStack } from "@chakra-ui/react";

import HeroVideo from "../components/HeroVideo";
import Layout from "../components/Layout";
import Block from "../components/Block";
import SponsorRoll from "../components/SponsorRoll";
import Container from "../components/Container";
import PreviewBlog from "../components/PreviewBlog";

export const IndexPageTemplate = ({ heroImage, title, slogan, main }) => (
  <>
    <HeroVideo img={!!heroImage.childImageSharp ? heroImage.childImageSharp.fluid.src : heroImage}>
      <Heading as="h1" size="xl">
        {title}
      </Heading>
      <Text>{slogan}</Text>
    </HeroVideo>
    <VStack spacing={0}>
      {main && main.blocks && main.blocks.map((block) => <Block key={JSON.stringify(block)} {...block} />)}
    </VStack>
    <Container>
      <Box py="3rem">
        <Heading as="h2">Senaste nytt</Heading>
        <PreviewBlog />
        <Center>
          <Link to="/nyheter">
            <Button bg="transparent" border="1px">
              Fler nyheter
            </Button>
          </Link>
        </Center>
      </Box>
    </Container>
    <SponsorRoll />
  </>
);

IndexPageTemplate.propTypes = {
  heroImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  slogan: PropTypes.string,
  main: PropTypes.shape({
    blocks: PropTypes.array,
  }),
};

const IndexPage = ({ data, ...props }) => {
  const { frontmatter } = data.markdownRemark;
  return (
    <Layout {...props}>
      <IndexPageTemplate
        heroImage={frontmatter.heroImage}
        title={frontmatter.title}
        slogan={frontmatter.slogan}
        main={frontmatter.main}
      />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        slogan
        heroImage {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        main {
          blocks {
            title
            content
            link
            dark
            reverse
            image {
              childImageSharp {
                fluid(maxWidth: 512, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;
