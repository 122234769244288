import React from "react";
import { LazyVideo } from "react-lazy-media";
import { Box } from "@chakra-ui/react";

const HeroVideo = ({ img, dark, children, ...props }) => {
  return (
    <Box
      className="hero-video"
      style={{
        color: dark ? "#000" : "#fff",
      }}
      {...props}
    >
      <LazyVideo
        classes={["hero-video__video"]}
        controls={false}
        minWidth="100%"
        preload
        autoplay
        loop
        muted
        playsinline
        disablePictureInPicture
        // poster={img}
        src={require("../img/tegen001-opt2.mp4")}
      />
      <Box className="hero-video__content">{children}</Box>
    </Box>
  );
};

export default HeroVideo;
