import React from "react";
import PropTypes from "prop-types";
import { graphql, StaticQuery } from "gatsby";
import { SimpleGrid, Box, Link } from "@chakra-ui/react";

import PreviewCompatibleImage from "./PreviewCompatibleImage";

const SponsorRoll = ({ data }) => {
  const { edges: posts } = data.allMarkdownRemark;

  return (
    <Box bg="white" p="3rem">
      <SimpleGrid columns={3} minChildWidth="250px" spacing={8}>
        {posts?.map(({ node: post }) => (
          <Box d="flex" alignItems="center" justifyContent="center" bg="transparent" transition="ease-in" key={post.id}>
            <Link d="block" target="_blank" rel="noreferrer" href={post.frontmatter.link}>
              {post.frontmatter.logo ? (
                <PreviewCompatibleImage
                  imageInfo={{
                    image: post.frontmatter.logo,
                    alt: `featured image thumbnail for ${post.frontmatter.title}`,
                  }}
                />
              ) : null}
            </Link>
          </Box>
        ))}
      </SimpleGrid>
    </Box>
  );
};

SponsorRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

export default () => (
  <StaticQuery
    query={graphql`
      query SponsorRollQuery {
        allMarkdownRemark(
          sort: { order: ASC, fields: [frontmatter___title] }
          filter: { frontmatter: { templateKey: { eq: "sponsor" } } }
        ) {
          edges {
            node {
              id
              fields {
                slug
              }
              frontmatter {
                title
                link
                logo {
                  childImageSharp {
                    fixed(width: 300) {
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <SponsorRoll data={data} count={count} />}
  />
);
