import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import { Box, Heading, HStack, useColorMode } from "@chakra-ui/react";
import marked from "marked";

import Container from "./Container";
import { HTMLContent } from "./Content";

const Block = ({ title, content, image, dark, link, reverse }) => {
  const { colorMode } = useColorMode();
  let bg = dark ? "gray.800" : "";
  let color = dark ? "gray.100" : "";

  if (colorMode === "light") {
    bg = dark ? "gray.100" : "";
    color = dark ? "gray.800" : "";
  }

  return (
    <Box width="100%" bg={bg} color={color} py="3rem">
      <Container width="100%" bg={bg} color={color}>
        <HStack
          align="flex-start"
          justify={{ base: "center", md: "inherit" }}
          flexDirection={{ base: "column", md: reverse ? "row-reverse" : "row" }}
        >
          {image ? (
            <Box
              maxW="500px"
              minW="250px"
              width="100%"
              height={[300, 400]}
              textAlign="center"
              style={{
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundImage: `url(${!!image.childImageSharp ? image.childImageSharp.fluid.src : image})`,
              }}
            />
          ) : null}
          {content ? (
            <Box width="100%" maxW="4xl" p={{ base: "2rem 0", md: "0 2rem" }}>
              <Heading mb="4" as="h3">
                {link ? <Link to={link}>{title}</Link> : title}
              </Heading>
              <HTMLContent className="content" content={marked.parse(content)} />
            </Box>
          ) : null}
        </HStack>
      </Container>
    </Box>
  );
};

Block.propTypes = {
  title: PropTypes.string,
  link: PropTypes.string,
  content: PropTypes.node.isRequired,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  dark: PropTypes.bool,
};

export default Block;
